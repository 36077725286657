<template>

    <Page title="Unpaid Vendor Bills" icon="mdi-cash-minus">
        not a correct page
        <smart-table
            v-if="tableItems.length"
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="20"
            class="elevation-1"
            @click:row="clickRow"
            dense
            isFilter
            show-expand
        >
            <template v-slot:expanded-item="{ item }">
                <td colspan="6" v-if="item.accountsLinkedTransactions">
                    <v-simple-table dense>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Transaction Type
                                </th>
                                <th class="text-left">
                                    Date
                                </th>
                                <th class="text-left">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="row in item.accountsLinkedTransactions"
                                v-bind:key="row.toString()"
                            >
                                <td>{{ row.accountsEntity }}</td>
                                <td>{{ getTransactionDate(row) }}</td>

                                <td>
                                    {{
                                        getTransactionAmount(
                                            row,
                                            item.accountsEntity,
                                            item.accountsId,
                                        )
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </td>
            </template>
        </smart-table>
    </Page>
</template>

<script>
import api from '@/api';

const tableHeaders = [
    {
        text: 'Company',
        align: 'left',
        sortable: true,
        value: 'accountsCompanyId',
    },
    {
        text: 'Vendor',
        align: 'left',
        sortable: true,
        value: 'vendor.name',
    },
    {
        text: 'Doc Date',
        align: 'left',
        sortable: true,
        value: 'documentDate',
    },
    {
        text: 'Due Date',
        align: 'left',
        sortable: true,
        value: 'dueDate',
    },
    {
        text: 'Total',
        align: 'right',
        sortable: true,
        value: 'documentTotal',
        customComponent: 'Currency',
    },
    {
        text: 'Balance',
        align: 'right',
        sortable: true,
        value: 'documentBalance',
        customComponent: 'Currency',
    },
];

export default {
    name: 'BillList',
    data() {
        return {
            tableHeaders,
            tableItems: [],
            tableData: null,
            allData: null,
            isDataLoaded: false,
        };
    },
    methods: {
        getTransactionDate(transaction) {
            const record = this.allData.find(
                oneEl =>
                    oneEl.accountsId === transaction.accountsId &&
                    oneEl.accountsEntity === transaction.accountsEntity,
            );
            if (record) return record.documentDate;
            return 'no record';
        },

        getTransactionAmount(linkedTransaction, accountsEntity, accountsId) {
            const record = this.allData.find(
                oneEl =>
                    oneEl.accountsId === linkedTransaction.accountsId &&
                    oneEl.accountsEntity === linkedTransaction.accountsEntity,
            );
            const transaction =
                record &&
                record.accountsLinkedTransactions.find(
                    oneLinked =>
                        oneLinked.accountsEntity === accountsEntity &&
                        oneLinked.accountsId === accountsId,
                );
            if (transaction) return transaction.transactionAmount;
            return 'no record';
        },
        async getTableData() {
            const result = await api.post(this.$store, 'bill/transaction/outstanding/', {
                isQBUnpaid: true,
                accountsCompanyId: '123146150041704',
            });
            this.allData = result.data;
            this.tableItems = result.data.filter(
                oneElement => oneElement.accountsEntity === 'Bill',
            );
            // this.setTableData();
            this.isDataLoaded = true;
        },
        clickRow(row) {
            console.log({ row });
            this.$router.push(`bill/${row.source}/${row.id}`);
        },
    },
    async mounted() {
        await this.getTableData();
    },
};
</script>

<style scoped></style>
